























import InfoFooter from '../components/InfoFooter.vue'

export default {
  name: 'EventNotFound',

  components: {
    InfoFooter
  }
}
