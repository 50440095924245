import Vue from 'vue'
import { moduleFormats, PackageFormat } from '../../utils/package-formats'
import { Module } from '../../types/graphql'

export default Vue.extend({
  data () {
    return {
      moduleFormats
    }
  },

  methods: {
    getModuleFormat (module: Module): PackageFormat | undefined {
      return this.moduleFormats.find((f:PackageFormat) => f.key === module.type)
    }
  }
})
