



























import Vue from 'vue'
import { Query, RunDashboard, RunRestricted } from '@simpl/core/types/graphql'
import { RUN_DASHBOARD, RUN_UPDATED } from '../graphql'

import EventOverview from './EventOverview.vue'
import EventCountdown from '../components/EventCountdown.vue'
import EventLocked from './EventLocked.vue'
import EventNotFound from './EventNotFound.vue'
import EventRunning from './EventRunning.vue'
import InfoFooter from '../components/InfoFooter.vue'
import { mapState } from 'vuex'
import { MUTATIONS } from '@simpl/core/plugins/store/consts'

export default Vue.extend({
  name: 'EventView',

  components: {
    EventCountdown,
    EventOverview,
    EventLocked,
    EventRunning,
    EventNotFound,
    InfoFooter
  },

  props: {
    slug: String
  },

  data: function () {
    return {
      runDashboard: null! as RunDashboard,
      loading: 0,
      usersOnline: null! as any
    }
  },

  computed: {
    ...mapState({
      loggedIn: (state: any) => !!state.auth.token && (!state.auth.user.tfa_enabled || !!state.auth.twoFactorToken)
    }),
    notFound (): boolean | null | undefined {
      return this.runDashboard!.status === 'run-not-found'
    },
    run (): RunRestricted | null | undefined {
      return this.runDashboard?.run
    },
    runSuccess (): boolean | null | undefined {
      return this.run && this.runDashboard!.status === 'success'
    },
    runRunning (): boolean | null | undefined {
      return this.runDashboard?.configuration?.status === 'running' ||
        this.runDashboard?.configuration?.status === 'inactive'
    },
    registerFirst (): boolean | null | undefined {
      return this.runRunning && this.runDashboard.configuration &&
        (this.runDashboard.configuration?.show_registration ||
          this.runDashboard.configuration.alerts.includes('login')) &&
        (this.runDashboard.configuration.user.status === 'none' ||
          this.runDashboard.configuration.user.status === 'invited')
    },
    views (): Record<string, any> {
      return [{
        key: 'unknown',
        show: this.notFound,
        component: EventNotFound
      }, {
        key: 'locked',
        show: !this.runSuccess,
        component: EventLocked
      }, {
        key: 'pre',
        show: this.runSuccess && (!this.runRunning || this.registerFirst),
        component: EventOverview
      }, {
        key: 'running',
        show: this.runSuccess && (this.runRunning && !this.registerFirst),
        component: EventRunning
      }]
    }
  },

  watch: {
    runDashboard: {
      deep: true,
      handler (v) {
        if (!v || this.loggedIn) return

        const availableLanguages = this.runDashboard.run?.tags.filter(tag => tag.category!.identifier === 'language')
        this.$store.commit(MUTATIONS.SET_AVAILABLE_LANGUAGES, availableLanguages)
      }
    },
    '$store.state.auth.anonUsername': function () {
      this.$pusher.unsubscribe(`presence-users-online-${this.slug}`)
      this.usersOnline = this.$pusher.subscribe(`presence-users-online-${this.slug}`)
    }
  },

  apollo: {
    runDashboard: {
      query: RUN_DASHBOARD,

      variables (): object {
        return {
          slug: this.slug
        }
      },

      fetchPolicy: 'no-cache',

      update (result: Query) {
        return result.runDashboard
      },

      error (error): void {
        console.error(error)
      },

      loadingKey: 'loading',

      subscribeToMore: {
        document: RUN_UPDATED,

        fetchPolicy: 'no-cache',

        skip (): boolean {
          return !this.run
        },

        variables (): Record<string, any> {
          return {
            run_id: this.run!.id
          }
        },

        updateQuery (previous: RunDashboard, { subscriptionData }: any) {
          const runUpdated = subscriptionData.data.runUpdated

          this.runDashboard!.run!.properties = runUpdated.properties
          this.runDashboard!.run!.active = runUpdated.active

          this.runDashboard!.run!.modules = runUpdated.modules
        }
      }
    }
  },

  created () {
    // FIXME: no cache fetch policy not working
    this.runDashboard = null!

    this.usersOnline = this.$pusher.subscribe(`presence-users-online-${this.slug}`)
  },
  mounted () {
    this.$eventBus.on('participantRegistered', this.refetch)
  },
  beforeDestroy () {
    this.$eventBus.off('participantRegistered', this.refetch)
  },

  methods: {
    refetch () {
      this.$apollo.queries.runDashboard.refetch()
    }
  }
}
)
