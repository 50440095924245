
















































































































































































































































import mixins from 'vue-typed-mixins'
import { REGISTER_PARTICIPANT } from '@simpl/auth/graphql'
import { USER_EXISTS } from '@simpl/access-control/graphql'
import { ParticipantRegistrationInput, RunRestricted, UserExists, Wave } from '@simpl/core/types/graphql'
import { unsavedChanges } from '@simpl/core/utils/message'
import WaveManager from '../../mixins/WaveManager'
import DomainMarketSelection from '@simpl/core/components/DomainMarketSelection.vue'
import PrivacyConsentCheckbox from '@simpl/core/components/PrivacyConsentCheckbox.vue'
import PasswordHints from '@simpl/auth/components/PasswordHints.vue'
import PasswordValidator from '@simpl/auth/mixins/PasswordValidator'
import ValidationRules from '@simpl/core/mixins/utils/ValidationRules'
import { TranslateResult } from 'vue-i18n'
import { browserLanguageShort } from '@simpl/core/utils'

function initialState () {
  return {
    valid: true,

    firstname: '',
    lastname: '',
    email: '',
    username: '',

    showPassword: false,
    showPasswordHints: false,
    newPassword: '',
    confirmPassword: '',

    selectedWave: null! as Wave,
    marketId: '',

    termsAccepted: false,

    registrationCompleted: false,

    userExists: null! as UserExists,
    errorMessageEmailExists: '' as string | TranslateResult,
    errorMessageUsernameExists: '' as string | TranslateResult
  }
}

export default mixins(PasswordValidator, WaveManager, ValidationRules).extend({
  name: 'RegisterDialog',

  components: { DomainMarketSelection, PasswordHints, PrivacyConsentCheckbox },

  model: {},

  props: {
    value: Boolean,
    run: Object as () => RunRestricted
  },

  data () {
    return {
      ...initialState()
    }
  },

  computed: {
    show: {
      get (): boolean { return this.value },
      set (v: boolean) {
        this.$emit('input', v)
      }
    },
    waves (): Wave[] {
      const today = new Date()

      return this.run.waves.filter(wave => new Date(wave.starts_at) >= today)
    },
    availableWaves (): Wave[] {
      return this.waves.filter(wave => wave.participant_max - wave.participant_count > 0 || wave.participant_max === -1)
    },
    showWaveSelection (): boolean {
      return this.waves.length > 1 || !this.availableWaves.length
    },
    userExistsInOtherDomain (): boolean {
      return this.userExists?.status === 'other-domain'
    }
  },

  watch: {
    show (v) {
      if (v) {
        if (this.availableWaves.length && !this.showWaveSelection) {
          this.selectedWave = this.availableWaves[0]
        }
        return
      }

      Object.assign(this.$data, {
        ...initialState()
      })
    },
    termsAccepted (v) {
      console.log('termsAccepted', v)
    }
  },

  methods: {
    async emailExists () {
      const exists = await this.$apollo.query({
        query: USER_EXISTS,
        variables: {
          type: 'email',
          query: this.email
        }
      })

      this.userExists = exists.data.userExists

      if (this.userExists.exists) {
        this.handleUserAlreadyExists('email')
      }
    },
    async usernameExists () {
      const exists = await this.$apollo.query({
        query: USER_EXISTS,
        variables: {
          type: 'username',
          query: this.username
        }
      })

      this.userExists = exists.data.userExists

      if (this.userExists.exists) {
        this.handleUserAlreadyExists('username')
      }
    },
    handleUserAlreadyExists (type: string) {
      const statusMsg = this.userExists.status!.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
        return g1.toUpperCase() + g2.toLowerCase()
      }).replace('-', '')

      if (type === 'email') {
        this.errorMessageEmailExists = this.$t(`user.error.alreadyExists${statusMsg}`)
      }

      if (type === 'username') {
        this.errorMessageUsernameExists = this.$t(`user.error.alreadyExists${statusMsg}`)
      }

      if (this.userExists.status === 'other-domain') {
        this.firstname = 'shallnotbeempty'
        this.lastname = 'shallnotbeempty'
        this.newPassword = 'shallnotbeempty'
        this.confirmPassword = 'shallnotbeempty'
      }
    },
    async registerParticipant () {
      if (!this.termsAccepted) return
      const variables: ParticipantRegistrationInput = {
        run_id: this.run.id,
        new_user: {
          firstname: this.firstname,
          lastname: this.lastname,
          username: this.username,
          email: this.email,
          languagecode: this.$store.state.auth?.temporaryLanguage || browserLanguageShort,
          password: this.newPassword
        }
      }

      if (this.marketId) {
        (variables.new_user! as any).market_id = this.marketId
      }

      if (this.selectedWave) {
        variables.wave_id = this.selectedWave.id
      }

      try {
        await this.$apollo.mutate({
          mutation: REGISTER_PARTICIPANT,
          variables: {
            data: variables
          }
        })

        localStorage.setItem('registered', 'true')
        this.registrationCompleted = true
      } catch (error) {
        console.warn(error.message)
      }
    },
    async cancel () {
      this.show = await unsavedChanges(this)
    }
  }
})
