export type PackageFormat = {
  icon: string,
  key: string,
  color: string,
  iconOffset?: string,
  textColor?: string
  accept?: string,
  upload?: boolean,
  uploadAsPackage?: boolean
}

export const moduleFormats = [{
  icon: 'mdi-book-open',
  key: 'package',
  color: '#64c646',
  accept: '.zip',
  upload: true,
  uploadAsPackage: true
}, {
  icon: 'mdi-play-circle-outline',
  key: 'media',
  color: '#00bcd4',
  accept: 'video/*,audio/*',
  upload: true
}, {
  icon: 'mdi-file-pdf-box',
  iconOffset: '-3px',
  key: 'pdf',
  color: '#f7d969',
  textColor: '#333',
  accept: '.pdf',
  upload: true
}, {
  icon: 'mdi-download',
  key: 'download',
  color: '#a6206a',
  accept: '*',
  upload: true
}, {
  icon: 'mdi-paperclip',
  iconOffset: '-6px',
  key: 'external',
  color: '#00c4b0'
}, {
  icon: 'mdi-view-dashboard',
  key: 'simpl',
  color: '#ff5900'
}, {
  icon: 'mdi-book-education',
  key: 'test',
  color: '#0074b2'
}] as PackageFormat[]
