







































































import Vue from 'vue'
import EventHeadline from '../components/EventHeadline.vue'
import EventCountdown from '../components/EventCountdown.vue'
import EventAccordion from '../components/EventAccordion.vue'
import InfoFooter from '../components/InfoFooter.vue'
import { RunRestricted } from '@simpl/core/types/graphql'
import { getKeyvisual } from '../../utils/keyvisual'

export default Vue.extend({
  name: 'EventOverview',

  components: {
    EventHeadline,
    EventCountdown,
    EventAccordion,
    InfoFooter
  },

  props: {
    runDashboard: Object
  },

  computed: {
    loggedIn (): Boolean {
      return !!this.$store.state.auth.token
    },
    run (): RunRestricted {
      return this.runDashboard!.run
    },
    nextStartingDate (): string | null {
      return (this.runDashboard!.configuration!.user!.wave) ? this.runDashboard!.configuration!.user!.wave.starts_at : this.runDashboard!.run.starts_at
    },
    keyVisual (): string | null | undefined {
      return getKeyvisual(this.run, this.$store.state.auth.user.languagecode)?.url
    }
  }
})
