














































































































































import mixins from 'vue-typed-mixins'
import { mapState } from 'vuex'
import ModuleFormats from '@simpl/core/mixins/utils/ModuleFormats'
import { PackageFormat } from '@simpl/core/utils/package-formats'
import { getTextForTemporaryUserLanguage } from '@simpl/core/utils'

export default mixins(ModuleFormats).extend({
  name: 'EventModuleTile',

  props: {
    module: Object,
    orderNumber: Number,
    locked: Boolean,
    to: String
  },

  computed: {
    ...mapState({
      loggedIn: (state: any) => !!state.auth.token
    }),
    moduleContentType (): PackageFormat {
      return this.getModuleFormat(this.module)!
    },
    isWorking (): boolean {
      return this.module.trackingData?.status === 'working'
    },
    started (): boolean {
      return ['working', 'completed', 'passed'].includes(this.module.trackingData?.status)
    },
    mustLogIn (): boolean {
      return this.module.tracking_type === 'personal' && !this.loggedIn
    },
    mustUnlock (): boolean {
      return !!this.module.code && !this.started
    },
    active (): boolean {
      return this.module.active && !this.locked
    },
    showProgressIcon (): boolean {
      if (!this.module.trackingData) return false

      return ['working', 'completed', 'passed', 'failed'].includes(this.module.trackingData!.status)
    }
  },

  methods: {
    getTextForTemporaryUserLanguage,

    onClick () {
      if (this.mustLogIn) {
        this.$emit('login')
      } else if (this.mustUnlock) {
        this.$emit('unlock')
      } else {
        this.$router.push(this.to)
      }
    }
  }
})
