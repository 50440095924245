

















































































































































































import EventHeadline from '../components/EventHeadline.vue'
import EventModuleTile from '../components/EventModuleTile.vue'
import LoginDialog from './dialogs/LoginDialog.vue'
import UnlockModuleDialog from './dialogs/UnlockModuleDialog.vue'
import SetAnonUsernameDialog from '../../../core/components/dialogs/SetAnonUsernameDialog.vue'
import InfoFooter from '../components/InfoFooter.vue'
import { RunDashboard, RunRestricted, Module, Query, Session, SessionTrackingStatus } from '@simpl/core/types/graphql'
import { RUN_DASHBOARD_SESSION } from '../graphql'
import { mapState } from 'vuex'
import { getTextForUserLanguage } from '@simpl/core/utils'
import { MUTATIONS } from '@simpl/auth/store/consts'
import mixins from 'vue-typed-mixins'
import { getKeyvisual } from '../../utils/keyvisual'
import IssueCertificate from '../mixins/IssueCertificate'

export type ModuleExtended = Module & {
  active: Boolean,
  trackingData: SessionTrackingStatus,
  code?: string
}

export default mixins(IssueCertificate).extend({
  name: 'EventRunning',

  components: {
    EventHeadline,
    EventModuleTile,
    LoginDialog,
    UnlockModuleDialog,
    SetAnonUsernameDialog,
    InfoFooter
  },

  props: {
    runDashboard: Object as () => RunDashboard
  },

  data () {
    return {
      sessions: null! as Session[],
      userTracking: {},
      showLoginDialog: false,
      showUnlockModuleDialog: false,
      showSetAnonUsernameDialog: false,
      selectedModuleCode: null as string | null,
      selectedModuleLink: null as string | null,
      certUrl: null! as string
    }
  },

  computed: {
    ...mapState({
      loggedIn: (state: any) => !!state.auth.token
    }),
    run (): RunRestricted | null | undefined {
      return this.runDashboard?.run
    },
    active (): boolean | null | undefined {
      return this.run?.active && this.runDashboard?.configuration?.modules === 'active'
    },
    consecutive (): boolean {
      return this.run?.properties?.consecutive
    },
    remoteControlled (): boolean {
      return !!this.run?.properties.remoteControlled
    },
    openModule (): string | null | undefined {
      return this.run?.properties.moduleActive
    },
    mustSetAnonUsername (): boolean {
      return !this.loggedIn && !!this.run?.properties?.setAnonUsername && !this.$store.state.auth.anonUsername
    },
    modules (): ModuleExtended[] {
      const modules = this.run?.modules?.map((module: Module) => {
        return ({
          ...module,
          // TODO: @cb, müssen immer properties vorhanden sein?
          // active: this.validateDate(module) && !module.run_pivot?.properties.deactivated,
          active: this.validateDate(module) && !module.run_pivot?.properties?.deactivated,
          trackingData: this.sessions?.find(session => session.module?.identifier === module.identifier)?.tracking_status ||
            this.$store.state.module?.sessions[`${this.run?.id}_${module.id}`]?.tracking_status,
          code: module.run_pivot?.properties?.code
        })
      }) || []
      return this.run?.properties
        .moduleAgenda?.map((id: string) => modules.find(module => module.id === id)) || modules
    },
    keyVisual (): string | null | undefined {
      return getKeyvisual(this.run, this.$store.state.auth.user.languagecode)?.url
    },

    moduleCount (): Number {
      return this.modules.length
    }
  },

  watch: {
    openModule: {
      immediate: true,
      handler (v) {
        if (!v ||
          !this.run?.active ||
          (this.$store.state.auth && this.$store.state.auth.lastOpenedModule === v) ||
          !this.run?.properties.remoteControlled) return

        this.autoOpenModule(v, true)
      }
    }
  },

  mounted () {
    if (this.mustSetAnonUsername) {
      this.showSetAnonUsernameDialog = true
    }
  },

  methods: {
    /**
     * This flow of async issuing and presenting another download button was added,
     * because of iOS blocking any `window.open` within async functions.
     */
    async asyncIssueCertificate () {
      this.certUrl = await this.issueCertificate(this.run!.id, this.$store.state.auth.user.id)
    },
    downloadCert () {
      if (this.certUrl) {
        window.open(this.certUrl, '_blank')
      }
    },
    validateDate (module: Module): boolean {
      if (!module.active) return false

      if (!module.activates_at && !module.expires_at) return true

      const today = new Date()
      const activatesAt = module.activates_at ? new Date(module.activates_at) : null
      const expiresAt = module.expires_at ? new Date(module.expires_at) : null

      if (activatesAt && expiresAt) {
        return today >= activatesAt && today <= expiresAt
      }

      return activatesAt ? today >= activatesAt : today <= expiresAt!
    },
    async autoOpenModule (identifier: string, withConfirm: boolean = false) {
      const module = this.run!.modules!.filter((module:any) => module.identifier === identifier)[0]

      if (withConfirm) {
        const answer = await this.$confirm({
          color: 'info',
          message: this.$t('run.notifications.autoOpenModuleConfirm', { moduleName: getTextForUserLanguage(module) }),
          buttons: [{
            text: this.$t('core.action.cancel'),
            type: 'outlined',
            answer: false
          }, {
            text: this.$t('core.action.continue'),
            color: 'info',
            answer: true
          }]
        })

        if (!answer) return
      }

      if (module.tracking_type === 'personal' && !this.loggedIn) {
        this.selectedModuleLink = `/content/${this.run!.identifier}/${identifier}`
        this.showLoginDialog = true
        this.$store.commit(`auth/${MUTATIONS.SET_LAST_OPENED_MODULE}`, identifier)
        return
      }

      const { href } = this.$router.resolve({ path: `/content/${this.run!.identifier}/${identifier}` })
      this.$router.push(href).then(() => {})
      this.$store.commit(`auth/${MUTATIONS.SET_LAST_OPENED_MODULE}`, identifier)
    },
    isPreviousCompleted (modules: ModuleExtended[], index: number): boolean {
      if (!this.consecutive) return true
      if (index < 1) return true

      const previousIncomplete = modules
        .filter((module: any, i: number) =>
          module.active && i < index &&
          module.tracking_type !== 'none' &&
          module.trackingData?.status !== 'completed'
        )

      return !previousIncomplete.length || (!previousIncomplete.length && !modules[index - 1].active)
    },
    unlockModule (module: ModuleExtended) {
      this.selectedModuleCode = module.code!
      this.selectedModuleLink = `/content/${this.run!.identifier}/${module.identifier}`
      this.showUnlockModuleDialog = true
    }
  },

  apollo: {
    sessions: {
      query: RUN_DASHBOARD_SESSION,

      fetchPolicy: 'cache-and-network',

      skip (): boolean {
        return !this.run || !this.loggedIn
      },

      variables (): object {
        return {
          run_id: this.run!.id
        }
      },

      update (result: Query): Session[] {
        return (result.runDashboardSessions || []).filter(Boolean) as Session[]
      },

      error (error) {
        console.error(error)
      },
      loadingKey: 'sessionsLoading'
    }
  }
})
