






















































































































import mixins from 'vue-typed-mixins'
import { ParticipantRegistrationInput, RunDashboard, Wave } from '@simpl/core/types/graphql'
import { REGISTER_PARTICIPANT } from '@simpl/auth/graphql'
import { appCreated } from '@simpl/core/init-app'
import RegisterDialog from '../views/dialogs/RegisterDialog.vue'
import LoginDialog from '../views/dialogs/LoginDialog.vue'
import { mapState } from 'vuex'
import { TranslateResult } from 'vue-i18n'
import { getTextForUserLanguage } from '@simpl/core/utils'
import WaveManager from '../mixins/WaveManager'
import { ISSUE_CERTIFICATE } from '../graphql'
import IssueCertificate from '../mixins/IssueCertificate'

export default mixins(IssueCertificate, WaveManager).extend({
  name: 'EventAccordion',

  components: { LoginDialog, RegisterDialog },

  props: {
    runDashboard: Object as () => RunDashboard
  },

  data () {
    return {
      expanded: null! as number,
      loading: false,
      showWaveDialog: false,
      showRegistrationDialog: false,
      showLoginDialog: false,
      selectedWave: null! as Wave
    }
  },

  computed: {
    // run
    runEnded (): boolean {
      return this.runDashboard!.configuration!.status === 'post'
    },
    runRunning (): boolean {
      const today = new Date()

      return (today >= this.runDashboard!.run!.starts_at && today <= this.runDashboard!.run!.ends_at) ||
        this.runDashboard!.run!.status === 'running'
    },
    waves (): Wave[] {
      const today = new Date().toISOString().substr(0, 10)
      return [...this.runDashboard!.run!.waves]
        .sort((a, b) => (a.starts_at < b.starts_at) ? -1 : 1)
        .filter(wave => wave.starts_at >= today)
    },
    wavesMissing (): Boolean {
      return !!this.runDashboard?.run?.dedicated_waves && !this.waves.length
    },
    hideSelectWaveButton (): boolean {
      return this.registered ||
        !this.runDashboard?.run?.dedicated_waves ||
        this.waves.length <= 1 ||
        !!this.runDashboard?.configuration?.user.wave ||
        this.showRegistration
    },
    showRegistration (): boolean {
      return !this.runEnded && this.noTrackingData && this.runDashboard!.configuration!.show_registration &&
        !this.loggedIn && !this.registered
    },
    // user
    registered (): boolean {
      return !this.runDashboard!.configuration!.show_registration ||
        this.runDashboard!.configuration!.user!.status === 'registered'
    },
    completed (): boolean {
      return this.runEnded && this.runDashboard!.configuration!.user!.status === 'completed'
    },
    noTrackingData (): boolean {
      return this.runDashboard!.configuration!.user!.status === 'none'
    },
    registrationContentText (): TranslateResult {
      if (this.registered && this.loggedIn) {
        return this.runDashboard!.configuration!.user!.wave
          ? this.$t(`run.eventView.notifications.${this.runDashboard!.run!.type}.registeredWithDate`, [this.runDashboard!.configuration!.user!.wave.starts_at])
          : this.$t(`run.eventView.notifications.${this.runDashboard!.run!.type}.registered`)
      }
      if (localStorage.getItem('registered') === 'true') {
        return this.$t('run.eventView.validateEmail')
      }
      if (this.showRegistration) {
        return this.$t('run.eventView.registrationNewDescription')
      }
      if (!this.loggedIn) {
        return this.$t('run.eventView.logIn')
      }
      return (this.runDashboard!.run!.waves.length > 1) ? this.$t('run.eventView.registrationMultiDescription') : this.$t('run.eventView.registrationDescription')
    },
    // config
    states (): Record<string, any> {
      return {
        completed: {
          icon: 'mdi-check-circle-outline',
          color: 'success'
        },
        pending: {
          icon: 'mdi-checkbox-blank-circle-outline',
          color: 'grey'
        },
        locked: {
          icon: 'mdi-lock',
          color: 'grey'
        }
      }
    },
    pre (): Record<string, any>[] | boolean {
      return !this.runEnded && [{
        key: 'invitation',
        disabled: true,
        status: 'completed'
      }, {
        key: this.showRegistration ? 'registerNew' : 'registration',
        contentText: this.registrationContentText,
        buttons: [{
          text: this.runRunning ? this.$t(`run.eventView.notifications.${this.runDashboard!.run!.type}.confirmAndStart`) : this.$t('run.eventView.registration'),
          color: 'primary',
          action: this.registerParticipant,
          hidden: this.registered || this.showRegistration || this.wavesMissing
        }, {
          text: this.$t('run.eventView.chooseWave'),
          outlined: true,
          action: () => { this.showWaveDialog = true },
          hidden: this.hideSelectWaveButton
        }, {
          text: this.runRunning ? this.$t(`run.eventView.notifications.${this.runDashboard!.run!.type}.confirmAndStart`) : this.$t('run.registerParticipant.button'),
          color: 'primary',
          action: () => { this.showRegistrationDialog = true },
          hidden: !this.showRegistration || this.wavesMissing
        }, {
          text: this.$t('simplAuth.auth.login'),
          color: 'grey',
          action: () => { this.showLoginDialog = true },
          hidden: !this.showRegistration && !this.runDashboard!.configuration!.alerts.includes('login')
        }, {
          text: this.$t('run.registerParticipant.noWaves'),
          color: 'primary',
          disabled: true,
          action: '',
          hidden: !this.wavesMissing
        }],
        status: this.registered && this.loggedIn ? 'completed' : 'pending'
      }, {
        key: 'completed',
        disabled: true,
        status: 'locked'
      }, {
        key: 'additionalInfo',
        status: 'locked'
      }]
    },
    post (): Record<string, any>[] | boolean {
      const additionalDefault = this.$t(`run.eventView.notifications.${this.runDashboard!.run!.type}.eventEndedInfo`)
      const additionalContentText = (this.runDashboard.run!.properties.generateCertificate && this.completed) ? `${additionalDefault} ${this.$t('run.notifications.certificateNowAvailable')}` : additionalDefault
      this.expanded = (!this.noTrackingData && this.completed) ? 3 : -1
      return this.runEnded &&
        [{
           key: 'invitation',
           disabled: true,
           hidden: this.noTrackingData,
           status: this.noTrackingData ? 'pending' : 'completed'
         },
         {
           key: 'registration',
           disabled: true,
           hidden: this.noTrackingData,
           status: this.noTrackingData ? 'pending' : 'completed'
         }, {
           key: 'completed',
           contentText: this.$t(`run.eventView.notifications.${this.runDashboard!.run!.type}.${this.runDashboard!.configuration!.user.status}Post`,
                                { progress: this.runDashboard!.configuration!.user!.progress }),
           hidden: this.noTrackingData,
           status: !this.completed ? 'pending' : 'completed'
         }, {
           key: 'additionalInfo',
           contentText: additionalContentText,
           buttons: [{
             action: this.prepareIssueCertificate,
             loader: this.certificateInProgress,
             text: this.$t('run.global.certificate'),
             color: 'primary',
             icon: 'mdi-certificate',
             hidden: !this.runDashboard.run!.properties.generateCertificate || !this.completed
           }],
           status: this.runDashboard.run!.properties.generateCertificate && this.completed ? 'pending' : 'completed'
         }]
    },
    ...mapState({
      loggedIn: (state: any) => !!state.auth.token
    })
  },

  watch: {
    showWaveDialog (v) {
      if (!v) {
        this.selectedWave = null!
      }
    }
  },

  methods: {
    async registerParticipant (): Promise<void> {
      const variables: ParticipantRegistrationInput = {
        run_id: this.runDashboard!.run!.id
      }

      if (this.selectedWave) {
        variables.wave_id = this.selectedWave.id
      } else if (this.runDashboard!.configuration!.user!.wave) {
        variables.wave_id = this.runDashboard!.configuration!.user!.wave!.id
      } else if (!this.selectedWave && this.waves.length === 1) {
        variables.wave_id = this.waves[0].id
      } else if (!this.selectedWave && this.waves.length > 1) {
        variables.wave_id = this.waves.filter(wave => wave.starts_at === this.runDashboard!.run!.starts_at)[0].id
      }

      this.loading = true

      const registeredParticipant = await this.$apollo.mutate({
        mutation: REGISTER_PARTICIPANT,
        variables: {
          data: variables
        }
      })

      if (registeredParticipant) {
        this.$eventBus.emit('participantRegistered')
        this.loading = false
      }

      if (this.runRunning) {
        await appCreated(true)
      }

      this.showWaveDialog = false
    },
    async prepareIssueCertificate () {
      this.issueCertificate(this.runDashboard.run!.id, this.$store.state.auth.user.id)
    }
  }
})
