



































import mixins from 'vue-typed-mixins'
import ActiveDomainTags from '../mixins/apollo/ActiveDomainTags'
import StringToColor from '../mixins/utils/StringToColor'
import { Tag } from '../types/graphql'

export type TagExtended = Tag & {
  name: string
}

export default mixins(ActiveDomainTags, StringToColor).extend({
  name: 'DomainMarketSelection',

  model: {},

  props: {
    value: [Array as () => TagExtended[] | string[], String],
    returnObject: Boolean,
    multiple: Boolean,
    chips: Boolean,
    disabled: Boolean,
    restricted: Boolean,
    rules: Array,
    selectableTags: Array as () => Tag[]
  },

  computed: {
    selectedTags: {
      get (): TagExtended[] | string[] | string {
        return this.value
      },
      set (v: TagExtended[] | string[] | string) {
        this.$emit('input', v)
      }
    },
    allSelected (): boolean {
      return this.selectedMarketTagsLength === this.activeDomainMarketTags.length
    },
    selectIcon (): string {
      if (this.allSelected) return 'mdi-close-box'
      if (this.selectedMarketTagsLength) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    marketTags (): TagExtended[] {
      return this.restricted ? this.activeDomainMarketTags.filter(tag => this.isSelectable(tag)) : this.activeDomainMarketTags
    },
    selectedMarketTagsLength (): number {
      if (!Array.isArray(this.selectedTags)) return 0

      let selectedMarketTags = [] as TagExtended[] | string[]

      if ((this.selectedTags[0] as any).id) {
        selectedMarketTags = (this.selectedTags as TagExtended[]).filter(tag => tag.category!.identifier === 'market')
      } else {
        const marketTagIds = this.marketTags.map(tag => tag.id)

        selectedMarketTags = (this.selectedTags as string[]).filter(tag => marketTagIds.indexOf(tag) > -1)
      }

      return selectedMarketTags.length
    }
  },

  methods: {
    isSelectable (market: TagExtended): boolean {
      return this.selectableTags?.length ? !!this.selectableTags.find(tag => tag.id === market.id) : false
    }
  }
})
