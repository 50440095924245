





































































import mixins from 'vue-typed-mixins'

import ModuleFormats from '@simpl/core/mixins/utils/ModuleFormats'
import { Query, ModuleBasic } from '@simpl/core/types/graphql'
import GenericKeyvisuals from '../../runs/mixins/GenericKeyvisuals'
import { MODULE_BASIC } from '../graphql'

export default mixins(ModuleFormats, GenericKeyvisuals).extend({
  name: 'RunQrView',

  props: {
    runIdentifier: String,
    moduleIdentifier: String,
    code: String
  },

  data () {
    return {
      apiUrl: process.env.VUE_APP_API_URL || '',

      module: null! as ModuleBasic,
      loading: 0
    }
  },

  computed: {
    keyVisual (): string | null | undefined {
      return this.module?.keyvisual?.url || this.getRandomGenericKeyvisual()?.url
    }
  },

  apollo: {
    module: {
      query: MODULE_BASIC,

      variables (): object {
        return {
          slug: this.moduleIdentifier
        }
      },

      update (result: Query): ModuleBasic {
        const moduleBasic = result.moduleBasic || {} as ModuleBasic

        if (!moduleBasic.id) {
          this.$router.replace('/404')
          return null!
        }
        return moduleBasic
      },

      error (error: Error): void {
        console.error(error)
      },

      loadingKey: 'loading'
    }
  }
})
