import gql from 'graphql-tag'

export const LIST_MODULES = gql`query listModules (
    $filter: FilterInput!,
    $first: Int!,
    $page: Int,
    $orderBy: [OrderByClause!]
) {
    modules (filter: $filter, first: $first, page: $page, orderBy: $orderBy) {
        data {
            id
            identifier
            duration
            type
            tracking_type
            languagecode
            status
            active
            created_at
            updated_at
            tags {
                id,
                category {
                  id
                  identifier
                },
                identifier,
                texts {
                    identifier
                    languagecode
                    text
                }
            }
            texts {
                identifier
                languagecode
                text
            }
            keyvisual {
                id
                url
            }
            downloads {
                id
                masterFile {
                    id
                    url
                    filename
                    size
                    mime_type
                }
            }
            links {
                id
                is_master
                languagecode
                url
            }
            properties
            activates_at
            expires_at
            created_at
            updated_at
        }
        paginatorInfo {
            count
            currentPage
            perPage
            total
        }
    }
}`

export const LIST_MODULES_WITHOUT_FILES = gql`query listModulesWithoutFiles (
    $filter: FilterInput!,
    $first: Int!,
    $page: Int,
    $orderBy: [OrderByClause!]
) {
    modules (filter: $filter, first: $first, page: $page, orderBy: $orderBy) {
        data {
            id
            identifier
            duration
            type
            tracking_type
            languagecode
            status
            created_at
            updated_at
            activates_at
            expires_at
            tags {
                id,
                category {
                  id
                  identifier
                },
                identifier,
                texts {
                    identifier
                    languagecode
                    text
                }
            }
            texts {
                identifier
                languagecode
                text
            }
            keyvisual {
                id
                url
            }
        }
    }
}`

export const LIST_MODULES_BY_USAGE = gql`query listModulesByUsage (
    $first: Int,
    $limit: Int
) {
    modulesByUsage (first: $first, limit: $limit) {
        id
        identifier
        type
        active
        languagecode
        duration
        tracking_type
        texts {
            identifier
            languagecode
            text
        }
        created_at
        updated_at
    }
}`

export const GET_MODULE = gql`query GET_MODULE ($id: ID) {
  module (id: $id) {
      id
      identifier
      duration
      type
      tracking_type
      languagecode
      status
      active
      sticky
      activates_at
      expires_at
      texts {
          id
          identifier
          languagecode
          text
      }
      keyvisual {
          id
          url
      }
      tags {
          category {
              id
              identifier
          }
          id
          identifier
          texts  {
              identifier
              languagecode
              text
          }
      }
      downloads {
          id
          masterFile { 
              id 
              url 
              filename 
              languagecode
              size
              mime_type
              updated_at
          }
          files {
              id
              url
              filename
              languagecode
              size
              mime_type
              updated_at
          }
      }
      links {
          id
          is_master
          languagecode
          url
          updated_at
      }
      packages {
          id
          is_master
          languagecode
          path
          starter_file
          type
          properties
          updated_at
      }
      properties
  }
}`

export const MODULE_BASIC = gql`query MODULE_BASIC ($slug: String) {
    moduleBasic (slug: $slug) {
        id
        identifier
        type
        languagecode
        keyvisual {
         slug
         name
         languagecode
         filename
         size
         crc
         path
         mime_type
         url
        }
        texts {
            identifier
            languagecode
            text
        }
    }
}`

export const CREATE_MODULE = gql`mutation CREATE_MODULE ($data: NewModuleInput!) {
    createModule (data: $data) {
        id
        identifier
        duration
        type
        active
        tracking_type
        languagecode
        status
        sticky
        activates_at
        expires_at
        created_at
        updated_at
        texts {
            identifier
            languagecode
            text
        }
        keyvisual {
            id
            url
        }
        tags {
            category {
                id
                identifier
            }
            id
            identifier
            texts  {
                identifier
                languagecode
                text
            }
        }
        downloads {
            id
            masterFile {
                id
                url
                filename
                size
                mime_type
            }
        }
        links {
            id
            is_master
            languagecode
            url
        }
        packages {
            id
            is_master
            languagecode
            path
            starter_file
            type
        }
    }
}`

export const UPDATE_MODULE = gql`mutation UPDATE_MODULE ($data: UpdateModuleInput!) {
    updateModule (data: $data) {
        id
        identifier
        duration
        type
        tracking_type
        languagecode
        status
        sticky
        activates_at
        expires_at
        texts {
            identifier
            languagecode
            text
        }
        keyvisual {
            id
            url
        }
        tags{
            category {
                id
                identifier
            }
            id
            identifier
            texts  {
                identifier
                languagecode
                text
            }
        }
    }
}`

export const DELETE_MODULE = gql`mutation DELETE_MODULE ($id: ID!) {
    deleteModule(id: $id) {
        id
    }
}`

export const MODULE_NAME_EXISTS = gql`query moduleByIdentifier ($identifier : String!) {
    moduleByIdentifier(identifier: $identifier) {
        identifier
    }
}`

export const IMPORT_CONTENT_TEXTS = gql`mutation IMPORT_CONTENT_TEXTS ($module_id: ID!, $package: UploadFileInput!) {
    importContentTexts (module_id: $module_id, package: $package) {
        status
        imports
        errors
        warnings
    }
}`

export const DELETE_CONTENT_TEXTS = gql`mutation DELETE_CONTENT_TEXTS ($data: DeleteContentTextsInput!) {
    deleteContentTexts(data: $data)
}`

export const IS_AUTO_TRANSLATE_SOURCE_LANG = gql`query isAutoTranslateSourceLang ($languagecode : String!) {
    isAutoTranslateSourceLang(languagecode: $languagecode)
}`

export const GET_AUTO_TRANSLATE_TARGET_LANGS = gql`query getAutoTranslateTargetLangs {
    getAutoTranslateTargetLangs
}`

export const AUTO_TRANSLATE_MODULE = gql`mutation AUTO_TRANSLATE_MODULE ($data: AutoTranslateInput!) {
    autoTranslateModule(data: $data)
}`

export const MODULE_UPDATED = gql`subscription moduleUpdated ($module_id: ID!) {
    moduleUpdated (module_id: $module_id) {
        id
        identifier
        type
        languagecode
        keyvisual {
            slug
            name
            languagecode
            filename
            size
            crc
            path
            mime_type
            url
        }
        texts {
            identifier
            languagecode
            text
        }
        properties
    }
}`
