import Vue from 'vue'
import { FILES } from '@simpl/base-management/runs/graphql'
import { Query, File as GraphQLFile } from '@simpl/core/types/graphql'

export default Vue.extend({
  data: () => ({
    genericKeyvisuals: [] as GraphQLFile[]
  }),

  methods: {
    getRandomGenericKeyvisual (): GraphQLFile {
      return this.genericKeyvisuals[Math.floor(Math.random() * this.genericKeyvisuals.length)]
    }
  },

  apollo: {
    genericKeyvisuals: {
      query: FILES,

      variables: {
        first: 9999,
        filter: {
          filterBy: []
        },
        target_type: '%generic_keyvisuals%'
      },

      update (result: Query) {
        return result.files!.data
      },

      error (error: Error): void {
        console.error(error)
      }
    }
  }
})
