









































































import Vue from 'vue'
import ContactDialog from '@simpl/core/components/dialogs/ContactDialog.vue'
import ContentDialog from '@simpl/core/components/dialogs/ContentDialog.vue'
import LegalDisclosureDialog from '@simpl/core/components/dialogs/LegalDisclosureDialog.vue'
import { TranslateResult } from 'vue-i18n'
import i18n from '@simpl/core/plugins/i18n'
import { getLegalDisclosureContent } from '@simpl/core/utils/get-legal-disclosure-content'

export default Vue.extend({
  name: 'InfoFooter',

  components: {
    ContentDialog,
    ContactDialog,
    LegalDisclosureDialog
  },

  props: {
    hideContact: Boolean
  },

  computed: {
    imprintContent (): string {
      return getLegalDisclosureContent('imprint')
    },
    privacyContent (): string {
      return getLegalDisclosureContent('privacy')
    },
    hideLegalLink () {
      return this.$store.state.auth.domain?.properties?.hideLegalLink
    }
  }
})
