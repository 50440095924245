
































































import mixins from 'vue-typed-mixins'
import { getTextForTemporaryUserLanguage, getTextForLanguage } from '@simpl/core/utils'
import { RunDashboard, RunRestricted } from '@simpl/core/types/graphql'
import RunDescription from '../../components/RunDescription.vue'
import WaveManager from '../mixins/WaveManager'
import { getLocaleDateTimeFormat } from '@simpl/core/utils/date-time-format'

export default mixins(WaveManager).extend({
  name: 'EventHeadline',

  components: { RunDescription },

  props: {
    showDescription: Boolean,
    runDashboard: Object as () => RunDashboard
  },

  computed: {
    run (): RunRestricted | null | undefined {
      return this.runDashboard?.run!
    },
    active (): boolean | null | undefined {
      return this.run?.active && this.runDashboard?.configuration?.modules === 'active'
    },
    inactive (): boolean | null | undefined {
      return !this.run?.active && this.runDashboard?.configuration?.status === 'inactive'
    },
    runEnded (): boolean {
      return this.runDashboard?.configuration!.status === 'post'
    },
    running (): boolean {
      return this.runDashboard?.configuration!.status === 'running'
    },
    date (): string {
      if (this.runDashboard?.configuration!.user!.wave) {
        return this.dateRange(this.runDashboard?.configuration!.user!.wave)
      }

      if (this.run?.starts_at === this.run?.ends_at) {
        return this.run?.starts_at ? getLocaleDateTimeFormat(this.run.starts_at) : ''
      }
      return this.run?.starts_at || this.run?.ends_at
        ? `${this.run.starts_at ? getLocaleDateTimeFormat(this.run.starts_at) : ''} - ${this.run.ends_at ? getLocaleDateTimeFormat(this.run.ends_at) : ''}`
        : ''
    },
    description (): string | void {
      const description = getTextForTemporaryUserLanguage(this.run, 'description', false)

      return description && description.replace(/(?:\r\n|\r|\n)/g, '<br>')
    },
    contactPerson (): string {
      const contactPerson = this.run?.properties.contactPerson
      return contactPerson
        ? this.$t('core.global.fullname', [contactPerson.firstname, contactPerson.lastname]) as string
        : ''
    }
  },

  methods: {
    getTextForTemporaryUserLanguage,
    getTextForLanguage,
    openEmail () {
      window.open('mailto:' + this.run!.properties.contactPerson.email)
    }
  }
})
