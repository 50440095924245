
















































































































import mixins from 'vue-typed-mixins'
import { TranslateResult } from 'vue-i18n'

// @ts-ignore
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'
import MobileDetection from '@simpl/core/mixins/utils/MobileDetection'

export default mixins(MobileDetection).extend({
  name: 'UnlockModuleDialog',

  components: { QrcodeStream },

  model: {},

  props: {
    value: Boolean,
    moduleCode: String,
    link: String
  },

  data: () => ({
    code: '',
    selectedOption: null as string | null,
    wrongCodeMessage: '' as string | TranslateResult,
    cameraAccessDenied: false,
    cameraAccessMessage: '' as string | TranslateResult,
    loading: false
  }),

  computed: {
    show: {
      get (): boolean {
        return this.value
      },
      set (v: boolean) {
        this.$emit('input', v)
      }
    },
    options (): any[] {
      return [{
        value: 'code',
        icon: 'mdi-lock-outline',
        text: this.$t('run.editAgenda.action.enterCode')
      }, {
        value: 'qr',
        icon: 'mdi-qrcode-scan',
        text: this.$t('run.qr.scan')
      }]
    }
  },

  watch: {
    code (v) {
      if (!v) this.wrongCodeMessage = ''
    }
  },

  methods: {
    cancel () {
      this.show = false
    },
    checkCode () {
      if (this.code === this.moduleCode) {
        this.loading = true
        this.$router.push(this.link)
      } else {
        this.wrongCodeMessage = this.$t('core.message.wrongCode')
      }
    },
    async onInit (promise: Promise<any>) {
      try {
        await promise
      } catch (error) {
        this.cameraAccessDenied = true

        if (this.$te(`run.qr.error.${error.name}`)) {
          this.cameraAccessMessage = this.$t(`run.qr.error.${error.name}`)
        } else {
          console.log(error.name)
        }
      }
    },
    async onDetect (promise: Promise<any>) {
      try {
        const { content } = await promise

        this.code = content
        this.checkCode()
      } catch (error) {
        console.log(error)
      }
    }
  }
})
