























































































import mixins from 'vue-typed-mixins'
import { RUN_BASIC } from '../graphql'

import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import ModuleFormats from '@simpl/core/mixins/utils/ModuleFormats'
import { Query, RunBasic } from '@simpl/core/types/graphql'
import { getKeyvisual } from '../../utils/keyvisual'

export default mixins(StringToColor, ModuleFormats).extend({
  name: 'RunQrView',

  props: {
    slug: String
  },

  data () {
    return {
      apiUrl: process.env.VUE_APP_API_URL || '',

      run: null! as any /* RunBasic */,
      qrUrl: '',
      qrImage: null! as string,
      loading: 0
    }
  },

  computed: {
    runKeyVisual (): string | null | undefined {
      return getKeyvisual(this.run, this.$store.state.auth.user.languagecode).url
    }
  },

  apollo: {
    run: {
      query: RUN_BASIC,

      variables (): object {
        return {
          slug: this.slug
        }
      },

      update (result: Query): RunBasic { // & { description: string } {
        const runBasic = result.runBasic || {} as RunBasic

        if (!runBasic.id) {
          this.$router.replace('/404')
          return null!
        }
        this.qrUrl = window.location.origin + '/' + runBasic.type + '/' + runBasic.identifier
        return runBasic
      },

      error (error): void {
        console.error(error)
      },

      loadingKey: 'loading'
    }
  }
})
