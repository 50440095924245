import Vue from 'vue'
import { ISSUE_CERTIFICATE } from '../graphql'

export default Vue.extend({
  data: () => ({
    certificateInProgress: false
  }),

  methods: {
    async issueCertificate (runId: string|number, userId: number) {
      this.certificateInProgress = true
      const result = await this.$apollo.query({
        query: ISSUE_CERTIFICATE,
        fetchPolicy: 'no-cache',
        variables: {
          run_id: runId,
          user_id: userId
        }
      })
      if (result.data) {
        this.certificateInProgress = false
        return result.data.issueCertificate
      } else {
        this.$notification.publish('bottom', {
          message: this.$t(result!.errors![0].message),
          borderColor: 'error'
        })
      }
      this.certificateInProgress = false
    }
  }
})
