































































import Vue from 'vue'
import LoginDialog from './dialogs/LoginDialog.vue'
import InfoFooter from '../components/InfoFooter.vue'

export default Vue.extend({
  name: 'EventLocked',

  components: {
    LoginDialog,
    InfoFooter
  },

  props: {
    runDashboard: Object
  },

  data () {
    return {
      showLoginDialog: false
    }
  },

  computed: {
    status (): string | null | undefined {
      return this.runDashboard?.status
    },
    notAuthenticated (): boolean {
      return this.status === 'not-authenticated'
    },
    notInvited (): boolean {
      return this.status === 'not-invited'
    }
  }
})
