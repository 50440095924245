import Vue from 'vue'
import { Wave } from '@simpl/core/types/graphql'
import { getTextForUserLanguage } from '@simpl/core/utils'
import { TranslateResult } from 'vue-i18n'
import { getLocaleDateTimeFormat } from '@simpl/core/utils/date-time-format'

export default Vue.extend({
  name: 'WaveManager',

  methods: {
    dateName (wave: Wave): string {
      return getTextForUserLanguage(wave, 'display_name', false) || ''
    },
    dateRange (wave: Wave): string {
      if (wave.starts_at === wave.ends_at) {
        return getLocaleDateTimeFormat(wave.starts_at)
      }

      return wave.starts_at || wave.ends_at
        ? `${wave.starts_at ? getLocaleDateTimeFormat(wave.starts_at) : ''} - ${wave.ends_at ? getLocaleDateTimeFormat(wave.ends_at) : ''}`
        : ''
    },
    quotaToString (wave: Wave): TranslateResult {
      if (wave.participant_max > 0) {
        const free = wave.participant_max - wave.participant_count
        return (free <= 0)
          ? this.$t('run.registerParticipant.noQuotaAvailable')
          : this.$t('run.registerParticipant.quotaAvailable', [free])
      }

      return this.$t('run.registerParticipant.quotaAllAvailable')
    },
    hasFreePlaces (wave: Wave): boolean {
      return wave.participant_max === -1 || wave.participant_max - wave.participant_count > 0
    }
  }
})
