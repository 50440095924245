import Vue from 'vue'
import { stringToColor } from '../../utils/core'

export default Vue.extend({
  filters: {
    stringToColor
  },
  methods: {
    stringToColor
  }
})
